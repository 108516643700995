
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "../store";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "NfzVisitType",
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const showNfzPopup = ref(false);

    const isNfzActive = store.getters.isNfzActive;
    const goToMakeAppointment = (nfz: boolean) => {
      router.push({
        name: "MakeAppointment",
        params: { nfzChoosen: nfz.toString() },
      });
    };

    return {
      t,
      isNfzActive,
      showNfzPopup,
      goToMakeAppointment,
    };
  },
});
